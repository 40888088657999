.json-search {
  max-width: 750px;
  margin: 0 auto;
}

.json-search input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.json-search button[type="submit"] {
  background-color: #4caf50; /* Green */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.json-search button[type="submit"]:hover {
  background-color: #3e8e41; /* Dark green */
}

.element {
  margin-top: 20px;
  margin-bottom: 250px;
}
