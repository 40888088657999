body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f4f4f4;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.form-check-input {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

.form-check-label {
  margin-left: 1.5rem;
}

.timer {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.quiz-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.quiz-question {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.quiz-options {
  margin-bottom: 2rem;
}

.quiz-option-label {
  display: block;
  margin-bottom: 0.5rem;
}

.quiz-option-input {
  margin-right: 0.5rem;
}

.result-container {
  text-align: center;
  margin-top: 3rem;
}

.result-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.result-score {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.result-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
