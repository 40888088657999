.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #222222;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar-subitem-text {
  font-size: 0.8rem;
}

.sidebar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
}

.nav-icon {
  position: fixed;

  top: 0px; /* adjust the top value as needed */
  left: 0px; /* adjust the right value as needed */
  z-index: 999;
}

@media (max-width: 768px) {
  /* adjust the max-width value as needed */
  .nav-icon {
    display: none;
  }
}
