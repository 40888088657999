body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Services */
.card-title {
  color: #01bf71;
  font-size: 24px;
}
.p-3 {
  padding: 0.2rem 1rem;
}
.card-text {
  padding: 0rem 0rem 1rem 0rem;
  font-weight: 500;
  font-size: 18px;
}

.card {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s;
  background-color: white;
  box-shadow: 5px 5px 5px 5px #e5e5e5;
}
.card:hover {
  transform: scale(1.03);
}

.services .col-md-4 {
  margin: 1rem 1rem 1rem 1rem;
}

.container {
  width: auto;
  margin-top: 30px;
}

.text {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Segoe UI", monospace;
  font-size: 18px;
  text-align: justify;
  margin: 15px;
}

.page-title {
  color: #8a25b1;
  font-family: "Michroma", sans-serif;
}

.page-title,
.entry-title {
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  padding: 0.5em 0.6em;
  margin-bottom: 0.5em;
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
  font-family: "Fjalla One", Tahoma, Verdana, Arial;
  color: #2277bb;
  line-height: 1.4em;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.content-section {
  margin-left: 30px;
  padding: 30px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 4px;
}
